/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { CustomProgressIndicatorSX } from '@/components/blocks/CustomProgressIndicator/style';
import { useLocalization } from '@/data/Localization';
import { Box, CircularProgress, LinearProgress, Paper, SxProps, Theme } from '@mui/material';
import { FC, useMemo } from 'react';

type CustomProgressIndicatorProps = {
	variant?: 'circular' | 'linear';
	padding?: 'array' | 'number';
	sx?: SxProps<Theme>;
};

export const CustomProgressIndicator: FC<CustomProgressIndicatorProps> = ({
	variant = 'circular',
	sx: _sx,
}) => {
	const CustomProgressIndicatorNLS = useLocalization('ProgressIndicator');
	const sx = useMemo(() => ({ ...CustomProgressIndicatorSX, ..._sx }), [_sx]);

	const a11yProps = {
		title: 'progress indicator',
		'aria-label': CustomProgressIndicatorNLS.label.t(),
	};

	return (
		<Paper sx={CustomProgressIndicatorSX} elevation={10}>

			{variant === 'circular' ? (
				<CircularProgress {...a11yProps} />
			) : (
				<LinearProgress sx={{ width: '100%', ...sx }} {...a11yProps} />
			)}
		</Paper>
	);
};
