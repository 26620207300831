/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { SxProps, Theme } from '@mui/material/styles';

export const CustomProgressIndicatorSX: SxProps<Theme> = (theme) => ({
	position: 'fixed',
	zIndex: '9',
	top: '50%',
	left: '50%',
	transform: 'translateX(-50%) translateY(-50%);',
	width: '100px',
	height: '100px',
	backgroundColor: '#fff',
	padding: '1rem',
	border: '1px solid #A3AAAF',
	borderRadius: '1rem',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});
